<template>
  <moe-page title="优惠券列表">
    <!-- 筛选信息 -->
    <moe-inquire @search="questionSearch">
      <el-form-item label="问题">
        <el-input v-model.trim="questionParams.problem" placeholder="输入问题/标签/答案" maxlength="100" clearable />
      </el-form-item>
      <el-form-item label="问题分类">
        <el-select v-model="questionParams.assortId" placeholder="选择问题分类" clearable>
          <el-option v-for="(item, index) in questionList" :key="index" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="设备型号">
        <el-select v-model="questionParams.deviceModelId" filterable placeholder="选择设备型号" clearable>
          <el-option v-for="(item, index) in deviceList" :key="index" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
    </moe-inquire>

    <moe-table ref="questionTable" url="/problem/page" :params="questionParams" :numberShow="false">
      <!-- 操作按钮 -->
      <template slot="tool">
        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="$moe_coordinator.navigateTo('/fqaManage/question/add')">新建问题</el-button>
      </template>

      <el-table-column label="问题" prop="problem" min-width="150" />
      <el-table-column label="问题分类" prop="assort" min-width="150" />
      <el-table-column label="标签" prop="tags" min-width="150" :show-overflow-tooltip="true">
        <template slot-scope="{ row }">
          {{ formatterTags(row) }}
        </template>
      </el-table-column>
      <el-table-column label="设备型号" prop="deviceName" :show-overflow-tooltip="true" />
      <el-table-column label="访问量" prop="hits" width="100" />
      <el-table-column label="更新时间" prop="updateTime" min-width="150">
        <template slot-scope="{ row }">
          {{ $moe_time.getTimeStamp(row.updateTime) }}
        </template>
      </el-table-column>

      <el-table-column label="操作" min-width="300" fixed="right">
        <template slot-scope="{ row }">
          <el-button type="success" size="mini" icon="el-icon-document" @click="handleAnswer(row)">查看解答</el-button>
          <el-button type="primary" size="mini" icon="el-icon-edit"  @click="$moe_coordinator.navigateTo(`/fqaManage/question/amend`, { id: row.id })">编辑</el-button>
          <el-button type="danger" size="mini" icon="el-icon-delete" @click="handleDelete(row)">删除</el-button>
        </template>
      </el-table-column>
    </moe-table>

    <moe-dialog :show="showDialog" title="查看回答" width="50%" @close="handleDialogClose()" :autoHeight="true" :closeOnClickModal="false">
      <div class="dialog_content overflow-h overflow-y-auto" style="height: 450px;">
        <div v-html="answer"></div>
      </div>
    </moe-dialog>
  </moe-page>
</template>

<script>
export default {
  name: 'FqaManageQuestionList',
  data() {
    return {
      questionParams: {
        pageNum: 1,
        pageSize: 10,
        problem: '',
        assortId: '',
        deviceModelId: '',
      },
      questionList: [],
      deviceList: [],
      showDialog: false,
      answer: '',
    }
  },
  methods: {
    formatterTags({ tags }) {
      if (!tags.length) {
        return '-'
      }
      return tags.join('、')
    },
    questionSearch(isSearch) {
      if (!isSearch) {
        this.questionParams = {
          pageNum: 1,
          pageSize: 10,
          problem: '',
          assortId: '',
          deviceModelId: '',
        };
      }

      this.$refs['questionTable'].searchData();
    },
    handleAnswer({ answer }) {
      this.answer = answer;

      this.showDialog = true;
    },
    handleDialogClose() {
      this.showDialog = false;
      this.answer = '';
    },
    handleDelete({ problem, id }) {
      this.$moe_layer.confirm(`您确定要删除当前选项“${problem}”吗？`, () => {
        this.$moe_api.FQA_API.delProblem({ id }).then((data) => {
          if (data.code == 200) {
            this.$moe_msg.success('删除成功');
            this.$refs['questionTable'].searchData();
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      });
    }
  },
  mounted() {
    /** 问题分类管理列表 */
    this.$moe_api.FQA_API.assortList().then((data) => {
      if (data.code === 200) {
        this.questionList = data.result.map(({ assort, id }) => {
          return {
            label: assort,
            value: id,
          }
        })
      }
    })

    /** 查看所有设备型号 */
    this.$moe_api.EQUIPMENT_API.readAll().then((data) => {
      if (data.code === 200) {
        this.deviceList = data.result.map(({ name, productModel, id }) => {
          return {
            label: `【${productModel}】${name}`,
            value: id
          }
        })
      }
    })
  },
}
</script>

<style lang="scss"></style>